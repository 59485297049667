.central-container {
    display: flex;
    padding: 0 10vw 10vh 10vw;
    justify-content: center;
}

.outer-container {
    display: flex;
    height: max-content;
    border-radius: 22px;
    border: 1px solid #E5E5E5;
    overflow: hidden;
}

@media(max-width:769px){
    .outer-container {
        flex-direction: column;
    }
}

.menu-column {
    border-right: 1px solid #ccc;
    background-color: #FBFCFF;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    display: flex;
    width: 45%;
    overflow: hidden;
}

@media(max-width:769px){
    .menu-column {
        width: 100%;
    }
}

.menu-column ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
}

.menu-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
    position: relative;
    flex: 1; /* Add this to make items grow and fill the space */
    display: flex;
    flex-direction: column;
}

.menu-item:last-child {
    border-bottom: none;
}

.menu-item.selected {
    background-color: white;
}

.menu-item.selected::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #DAFF47;
}

.menu-item:first-child.selected::before {
    border-top-left-radius: 22px;
}

.menu-item:last-child.selected::before {
    border-bottom-left-radius: 22px;
}

.menu-item-text-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: start;
}

.top-header {
    display: flex;
    justify-content: space-between;
}

.img-header {
    display: flex;
    gap: 10px;
    align-items: center;
}

.menu-item-header {
    font-size: 18px;
    font-weight: 600;
    color: #262626;
    font-family: 'Manrope', sans-serif;
}

.menu-item-lower {
    font-size: 14px;
    font-weight: 400;
    color: #5E6575;
    font-family: 'Poppins', sans-serif;
}
.header-image{
    width: 24px;
    height: 24px;
}
.content-image-container{
    padding: 20px;
    background: #FBFCFF;
    border-radius: 12px;
}
.content-header{
    font-size: 20px;
    font-weight: 700;
    color: #262626;
    font-family: 'Manrope', sans-serif;
}
@media(max-width: 821px){
    .content-header {
        font-size: 35px;
        font-weight: 700;
        color: #262626;
        font-family: 'Manrope', sans-serif;
    }
}
.content-desc{
    font-size: 16px;
    font-weight: 400;
    color: #5E6575;
    font-family: 'Poppins', sans-serif;
}
@media(max-width: 821px){
    .content-desc {
        font-size: 24px;
        font-weight: 400;
        color: #5E6575;
        font-family: 'Poppins', sans-serif;
    }
}
.menu-item-content-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media(max-width: 821px){
    .menu-item-content-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 65px;
    }
}
@media(max-width: 431px){
    .menu-item-content-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 0px;
    }
}

@media (max-width: 431px) {
    .content-header {
        font-size: 25px;
        font-weight: 700;
        color: #262626;
        font-family: 'Manrope', sans-serif;
    }
}
@media (max-width: 431px) {
    .content-desc {
        font-size: 15px;
        font-weight: 400;
        color: #5E6575;
        font-family: 'Poppins', sans-serif;
    }
}


