.central-container {
    display: flex;
    padding: 0 10vw 10vh 10vw;
    justify-content: center;
}

.agent-boxes {
    display: flex;
    gap: 20px; 
    width: 100%;
}
@media(max-width: 1025px){
    .agent-boxes {
        display: flex;
        gap: 20px; 
        width: 100%;
        flex-direction: column;
    } 
}

.agent-unit {
    background: #FBFCFF;
    border-radius: 22px;
    border: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box; 
    justify-content: space-between;
}
.agent-unit-green {
    background-image: url('../assests/green-back.svg'); 
    background-repeat: no-repeat; 
    background-size: cover; 
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box; 
    justify-content: space-between;
}

.agent-unit-purple{
    background: #D4A1F1;
    border-radius: 22px;
    border: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box; 
    justify-content: center;
    padding: 10px;
}
.agent-box-text-header-purple {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #7437BD;
    margin-bottom: 1px;
    font-family: 'Manrope', sans-serif;
}

.agent-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* height: 84vh; */
}
@media(max-width:789px){
    .agent-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


/* Styles for the first column */
.agent-column:first-child .agent-unit:first-child {
    flex: 2; /* This unit will take up 2/3 of the available space */
}
@media(max-width:789px){
    .agent-column:first-child .agent-unit:first-child {
        flex: none;
    }
    }

.agent-column:first-child .agent-unit:last-child {
    flex: 1; /* This unit will take up 1/3 of the available space */
}

/* Styles for the third column */
.agent-column:nth-child(3) .agent-unit:first-child {
    flex: 1; /* This unit will take up 1/3 of the available space */
}

.agent-column:nth-child(3) .agent-unit:last-child {
    flex: 2; /* This unit will take up 2/3 of the available space */
}

/* Styles for the middle column (optional, for consistency) */
.agent-column:nth-child(2) .agent-unit {
    flex: 1; /* Equal height for units in the middle column */
}

.agent-box-text {
    display: flex;
    flex-direction: column;
    padding: 19px;
}

.agent-box-text-header {
    font-size: 19px;
    font-weight: 600;
    line-height: 19.7px;
    color: #262626;
    margin-bottom: 1px;
    font-family: 'Manrope', sans-serif;
}

.agent-box-text-lower {
    font-size: 14px;
    line-height: 22.4px;
    font-weight: 400;
    color: #000000B2;
    font-family: 'Poppins', sans-serif;
}

.agent-box-text-lower-purple {
    font-size: 14px;
    line-height: 22.4px;
    font-weight: 400;
    color: #7437BD;
    font-family: 'Poppins', sans-serif;
}
