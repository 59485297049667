.central-container{
    display: flex;
    padding: 0 10vw 10vh 10vw;
    justify-content: center;
    flex-direction: column;
  }
.FaqHeader{
    display: flex;
    justify-content: space-between;
}
.blur-box-desc{
    background: #F3F3F3;
    padding: 20px;
    border-radius: 12px;
    gap: 10px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #667085;
    margin-top: -7px;
    width: 90%;
    font-family: 'Inter', sans-serif;
}
.FaqHeader-desc{
   font-weight: 500;
   font-size: 20px;
   color: #101828;
   font-family: 'Poppins', sans-serif;
}