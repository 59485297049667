.central-container {
    display: flex;
    padding: 0 10vw 10vh 10vw;
    justify-content: center;
}
.use-case-row {
    display: flex;
    gap: 40px;
    align-items: stretch;
}
@media(max-width:1025px) {
    .use-case-row {
        display: flex;
        gap: 40px;
        flex-direction: column;
    }  
}
.usecase-col1, .usecase-col2 {
    display: flex;
    flex-direction: column;
}
.usecase-col2 {
    flex-grow: 1;
}
.use-case-header {
    color: #A67CFF;
    font-size: 28px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
}

.use-case-heaser-text {
    font-weight: 700;
    font-size: 34px;
    line-height: 51px;
    color: #262626;
    font-family: 'Manrope', sans-serif;
}
.use-case-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    color: #000000B2;
    font-family: 'Poppins', sans-serif;
}
.use-case-purple-back {
    background: #B4AAFF;
    border-radius: 22px;
    display: flex;
    align-self: flex-start;
    margin-top: auto;
}
.data-freedom-content {
    background: #F7F6FF;
    padding: 22px;
    border-radius: 22px;
}
@media(max-width:769px) {
    .data-freedom-content {
        background: #F7F6FF;
        padding: 22px;
        border-radius: 22px;
        display: flex;
        flex-direction: column;
    }
}
.data-freedom-content-header {
    font-size: 20px;
    color: #262626;
    font-weight: 700;
    font-family: 'Manrope', sans-serif;
}
.data-freedom-content-lower{
    font-family: 'Poppins', sans-serif;
}

.usecaseimage {
    border-radius: 16px;
    width: 521px;
    height: 312px;
}

@media (max-width: 431px) {
    .usecaseimage {
        border-radius: 16px;
        width: 255px;
        height: 193px;
    }
}

