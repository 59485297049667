.header {
  box-sizing: border-box;
  width: 100vw;
  height: 80px;
  left: 0;
  top: 0;
  background: #FFFFFF; 
  border-bottom: 1px solid #CFCFCF;
  position: sticky; 
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10; 
}


.VideoTag{
  display: flex;
  width: 80vw;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 500px
}
@media(max-width: 431px){
  .VideoTag {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 500px;
}
}

.header-links{
  display: flex;
  gap: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32.55px;
  color: #555555;
  font-family: 'Inter', sans-serif;
}

@media(max-width:769px){
  .header-links {
    display: flex;
    gap: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32.55px;
    color: #555555;
    font-family: 'Inter', sans-serif;
}
}
@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.book-demo-section {
  opacity: 0;
  animation: popIn 0.5s ease-out forwards;
}

.demo-section {
  opacity: 0;
  animation: slideDown 0.5s ease-out 0.3s forwards;
}

.info-box-row {
  opacity: 0;
  animation: slideDown 0.5s ease-out 0.6s forwards;
}
.header-logo{
  width: 161px;
  height: 61.17px;
  cursor: pointer;
}
.top-header-link-text{
  cursor: pointer;
}

@keyframes popFromBelow {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-animate {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.scroll-animate.animate {
  animation: popFromBelow 0.3s ease-out forwards;
}

@media(max-width:426px){
  .header-logo{
    width: 100px;
    height: 40.17px;
    cursor: pointer;
  }
}
.full-length-container{
  width: 100vw;
}

.book-demo-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 800px;
  align-items: flex-end;

}

.foggy-image {
  width: inherit;
  position: relative;
  z-index: 1; 
}

.top-button{
  top: 20px;
  border-radius: 50px;
  background: #A67CFF;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 21.6px;
  font-size: 17px;
  text-align: center;
  padding: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  margin-top: 18px;
}
@media (max-width: 769px) {
  .top-button {
      top: 20px;
      border-radius: 50px;
      background: #A67CFF;
      color: #FFFFFF;
      font-weight: 700;
      line-height: 21.6px;
      font-size: 18px;
      text-align: center;
      padding: 22px;
      display: flex;
      gap: 3px;
      align-items: center;
      margin-top: 12px;
      cursor: pointer;
  }
}
@media (max-width: 432px) {
  .top-button {
      top: 20px;
      border-radius: 50px;
      background: #A67CFF;
      color: #FFFFFF;
      font-weight: 700;
      line-height: 21.6px;
      font-size: 18px;
      text-align: center;
      padding: 10px 5px 10px 14px;
      display: flex;
      gap: 3px;
      align-items: center;
      margin-top: 12px;
      cursor: pointer;
  }
}

.demo-section {
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  z-index: 2;
}

.demo-section-header {
  font-weight: 700;
  font-size: 52px;
  color: #000000;
  text-align: center;
  line-height: 83.3px;
  display: flex;
  width: 90%;
  margin-top: 25px;
  font-family: 'Manrope', sans-serif;
  justify-content: center;
}

@media(max-width:1025px){
  .demo-section-header {
    font-weight: 700;
    font-size: 50px;
    color: #000000;
    text-align: center;
    line-height: 83.3px;
    margin-top: 30px;
    font-family: 'Manrope', sans-serif;
  }
}
@media (max-width: 431px) {
  .demo-section {
      position: absolute;
      top: 0;
      left: 0%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      width: 100vw;
  }
}


  @media (max-width: 769px) {
    .demo-section-header {
        font-weight: 700;
        font-size: 40px;
        color: #000000;
        text-align: center;
        line-height: 55.3px;
        margin-top: 7px;
        font-family: 'Manrope', sans-serif;
        width: 90%;
    }
}
@media(max-width:431px){
  .botton-arrow-tilt{
    height: 14px;
    width: 14px;
    margin-top: 2px;
  }
}
@media (max-width: 769px) {
  .botton-arrow-tilt {
      height: 29px;
      width: 20px;
      margin-top: 3px;
      margin-left: 8px;
  }
}
@media (max-width: 426px) {
  .demo-section-header {
      font-weight: 700;
      font-size: 12px;
      color: #000000;
      text-align: center;
      line-height: 21.3px;
      margin-top: 7px;
      font-family: 'Manrope', sans-serif;
  }
}


.demo-section-header-subtext {
  margin-top: 10px; 
  font-weight: 400;
  font-size: 22px;
  color: #7B7583;
  text-align: center;
  line-height: 36px;
  width: 60%;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 1025px) {
  .demo-section-header-subtext {
      margin-top: 10px;
      font-weight: 400;
      font-size: 17px;
      color: #7B7583;
      text-align: center;
      line-height: 10px;
      width: 60%;
      font-family: 'Inter', sans-serif;
  }
}

@media (max-width: 769px) {
  .demo-section-header-subtext {
      margin-top: 10px;
      font-weight: 400;
      font-size: 21px;
      color: #7B7583;
      text-align: center;
      line-height: 50px;
      width: 60%;
      font-family: 'Inter', sans-serif;
  }
}
@media (max-width: 376px) {
  .demo-section-header-subtext {
      margin-top: 10px;
      font-weight: 400;
      font-size: 6px;
      color: #7B7583;
      text-align: center;
      line-height: 12px;
      width: 60%;
      font-family: 'Inter', sans-serif;
  }
}

.info-box{
  background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    width: 40%;
    transition: transform 0.5s;
}
.info-box h2{
  font-size: 1.4rem;
  margin: 0;
  font-family: 'Manrope';
}
.info-box p {
  color: #5e6575;
  font-family: 'Poppins';
}

.info-box:hover{
  box-shadow: 0px 4px 12px 0px #B5D82B9E;
  transform: scale(1.02);
  border: 1px solid #a3d63c;
}


.info-box-row{
  display: flex;
  gap: 14px;
  justify-content: center;
  z-index: 9;
  margin-top: -55px;
}
@media(max-width: 431px){
  .info-box-row {
    display: flex;
    gap: 14px;
    justify-content: center;
    z-index: 9;
    margin-top: -175px;
}
}
.info-box-header{
  font-size: 26px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}
.info-box-lower{
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
@media(max-width: 821px){
  .central-container {
    display: flex;
    padding: 0 5vw 10vh 5vw;
    justify-content: center;
}
}

.central-container{
  display: flex;
  padding: 0 10vw 10vh 10vw;
  justify-content: center;
}

.logo-container{
  display: flex;
  flex-direction: column;
}
.logo-header{
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: #262626;
  margin: 5px 0;
  font-family: 'Manrope', sans-serif;
  text-align: center;
}
.solution-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 0; 
}
@media(max-width:820px){
  .solution-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 0; 
  }
}

.solution-text {
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #A67CFF;
  margin-bottom: 5px; 
  font-family: 'Poppins', sans-serif;
  margin-top: -8px;
}

.solution-header {
  font-weight: 700;
  font-size: 34px;
  line-height: 40px; 
  color: #262626;
  margin: 5px 0; 
  font-family: 'Manrope', sans-serif;
}

.solution-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 40px; 
  color: #000000B2;
  margin-top: 5px;
  margin-bottom: 0; 
  font-family: 'Poppins', sans-serif;
}
.sap-buttons{
  display: flex;
  gap: 20px;
  align-self: center;
}
@media(max-width:820px){
  .sap-buttons{
    display: flex;
    gap: 30px;
    align-self: flex-start;
    flex-direction: column;
    
  }
}
.sap-boxes{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.sap-box-row-one, .sap-box-row-two {
  display: flex;
  gap: 20px; 
  width: 100%; 
}

@media(max-width:820px){
  .sap-box-row-one, .sap-box-row-two {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
}
}
@media(max-width:768px){
  .sap-box-row-one, .sap-box-row-two {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
}
}

.sap-unit {
  background: #FBFCFF;
  border-radius: 22px;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex: 1; 
  min-width: 0; 
  transition: transform 0.5s;
}

.sap-unit:hover{
    transform: scale(1.01);
    box-shadow: 0px 2px 4px #bcc1d4;
   
}

.sap-box-text {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 25px;
}
.sap-box-text-header{
  font-size: 19px;
  font-weight: 600;
  line-height: 19.7px;
  color: #262626;
  font-family: 'Manrope', sans-serif;
}
.sap-box-text-lower{
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}


.footer {
  background-image: url('./assests/footer-back.svg'); 
  background-repeat: no-repeat; 
  background-size: cover; 
  display: flex;
  box-sizing: border-box; 
  justify-content: center;
}
.footer-logo{
  width: 161px;
  height: 61.17px;
}
.footer-bottom{
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
}
.legal{
  display: flex;
  gap: 30px;
}
.central-container-footer {
  display: flex;
  padding: 3vw 10vw 3vh 10vw;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.footer-desc{
  display: flex;
  width: 27%;
  font-family: 'Manrope', sans-serif;
}
.footer-divider {
  border: none;
  height: 1px;
  background-color: #151414;
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .header-links {
      display: none; 
      font-family: 'Inter', sans-serif;
  }

  .hamburger-menu {
      display: block; 
  }
}
.logo-container-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
}

.logo-container-images img {
  max-width: 150px; 
  width: calc(50% - 20px); 
  margin: 10px; 
  box-sizing: border-box; 
}

@media(max-width: 1025px) {
  .logo-container-images img {
    width: calc(50% - 20px); /* Ensure images remain in two columns */
  }
}