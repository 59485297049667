.central-container{
    display: flex;
    padding: 0 10vw 10vh 10vw;
    justify-content: center;
  }
.desc-header{
    font-size: 42px;
    font-weight: 700px;
    font-family: 'Manrope', sans-serif;
}
.desc-desc{
    font-size: 18px;
    font-weight: 400;
    color:#5E6575;
    font-family: 'Poppins', sans-serif;
}
.transform-exp{
    background: #ECFAFF;
    border-radius: 22px;
    padding: 30px;
    display: flex;
}
@media(max-width:820px){
    .transform-exp {
        background: #ECFAFF;
        border-radius: 22px;
        padding: 30px;
        display: flex;
        flex-direction: column;
    }
}
.bottom-bot{
    display: flex;
    width: -webkit-fill-available;
    height: max-content;
    align-self: center;
    justify-content: flex-end;
}
@media(max-width:769px){
    .bottom-bot {
        display: flex;
        width: -webkit-fill-available;
        height: max-content;
        align-self: center;
        justify-content: flex-end;
        flex-direction: column;
    }
}
.bottom-button{
    top: 20px;
    border-radius: 50px;
    background: #262626; 
    color: #FFFFFF;
    font-weight: 700;
    line-height: 21.6px; 
    font-size: 17px;
    text-align: center; 
    padding: 20px; 
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top:30px;
    width: max-content;
    cursor: pointer;
  }

  @media(max-width:769px){
    .bottom-button {
        top: 20px;
        border-radius: 50px;
        background: #262626;
        color: #FFFFFF;
        font-weight: 700;
        line-height: 21.6px;
        font-size: 12px;
        text-align: center;
        padding: 9px;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 30px;
        width: max-content;
        cursor: pointer;
        margin-bottom: 20px;
    }
  }

  @media (max-width: 769px) {
    .bottom-tilt-arrow {
        height: 22px;
        width: 20px;
        margin-top: 2px;
    }
}