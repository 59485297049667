/* HamburgerMenu.css */
.hamburger-menu {
    position: relative;
}

.McButton {
    width: 25px;
    height: 30px; /* Adjusted height for better touch target */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.McButton b {
    width: 100%;
    height: 4px; /* Adjusted bar height */
    background-color: #393737;
    transition: all 0.3s ease;
}

.hamburger-menu.active .McButton b:nth-child(1) {
    transform: translateY(13px) rotate(45deg);
}

.hamburger-menu.active .McButton b:nth-child(2) {
    opacity: 0; /* Hide the middle bar */
}

.hamburger-menu.active .McButton b:nth-child(3) {
    transform: translateY(-13px) rotate(-45deg);
}

.menu-items {
    background: white; /* Background for the dropdown menu */
    position: fixed; /* Change to fixed to cover part of the screen */
    top: 80px; /* Adjust to the height of the header */
    left: 0; /* Align to left */
    width: 100vw; /* Full width of viewport */
    height: calc(100vh - 50px); /* Full height minus header height */
    border: none; /* Remove border */
    z-index: 1;
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.menu-items p {
    padding: 20px; /* Increased padding for better touch */
    cursor: pointer;
    text-align: center;
    width: 100%; /* Ensure full width for clickable area */
}

.menu-items p:hover {
    background: #A67CFF; /* Highlight on hover */
    color: white;
}

/* HamburgerMenu.css */
.hamburger-menu {
    display: none; /* Hide by default */
}

/* Show the hamburger menu on screens smaller than 768px */
@media (max-width: 768px) {
    .hamburger-menu {
        display: block; /* Show hamburger menu */
    }
}
